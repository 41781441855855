<div class="ui grid">
  <div class="row">
    <div class="column">
      <h1 class="ui header">Institution bearbeiten:</h1>
      <app-institution-form #institutionFormComponent></app-institution-form>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <button class="ui primary button" (click)="saveInstitution()"
              [disabled]="!institutionFormComponent.institutionForm.valid">Speichern
      </button>
    </div>
  </div>
</div>
