import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '../../core/auth/auth-guard';
import {ManageArticlesComponent} from '../admin/pages/manage-articles/manage-articles.component';
import {EditArticleComponent} from '../author/pages/edit-article/edit-article.component';
import {SubadminComponent} from './subadmin.component';
import {clientRoles} from '../../core/constants/client-roles.config';
import {ManageOwnInstitutionComponent} from './pages/manage-own-institution/manage-own-institution.component';
import {ManageInternalMessagesComponent} from './pages/manage-internal-messages/manage-internal-messages.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'institution',
    pathMatch: 'full'
  },
  {
    path: '',
    component: SubadminComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [
        clientRoles.IS_SUBADMIN,
      ]
    },
    children: [
      {
        path: 'institution',
        component: ManageOwnInstitutionComponent
      },
      {
        path: 'articles',
        component: ManageArticlesComponent,
        data: {subAdmin: true},
      },
      {
        path: 'articles/edit/:id',
        component: EditArticleComponent
      },
      {
        path: 'internalMessages',
        component: ManageInternalMessagesComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'institution',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubadminRoutingModule {
}
