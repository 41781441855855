<div class="ui padded grid">
  <div class="two wide column">
    <!--SIDEBAR-->
    <div class="ui vertical fluid tabular menu">
      <a class="item" routerLink="institution" routerLinkActive="active">
        Institution verwalten
      </a>
      <a class="item" routerLink="articles" routerLinkActive="active">
        Artikel verwalten
      </a>
      <a class="item" routerLink="internalMessages" routerLinkActive="active">
        Interne Mitteilungen
      </a>
    </div>
  </div>
  <div class="fourteen wide stretched column">
    <div class="ui segment">
      <!--CONTENT-->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

