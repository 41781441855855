import {Component, OnInit, ViewChild} from '@angular/core';

import {UsersService} from '../../../../core/services/users/users.service';
import {Institution} from '../../../../core/models/institutions/institutions.model';
import {InstitutionsService} from '../../../../core/services/institutions/institutions.service';
import {ToastService} from '../../../../core/services/toast/toast.service';

@Component({
  selector: 'app-manage-own-institution',
  templateUrl: './manage-own-institution.component.html',
  styleUrls: ['./manage-own-institution.component.css'],
  standalone: false,
})
export class ManageOwnInstitutionComponent implements OnInit {
  @ViewChild('institutionFormComponent') institutionFormComponent: any;
  public institution!: Institution;

  constructor(
    private usersService: UsersService,
    private institutionsService: InstitutionsService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.getInstitution();
  }

  private getInstitution() {
    this.usersService.getCurrentUser(['institution']).subscribe((user) => {
      this.institution = user.institution!;
      this.institutionFormComponent.updateValues(this.institution);
    });
  }

  public saveInstitution() {
    this.institutionsService.updateOne(
      Object.assign(this.institution, this.institutionFormComponent.getValues()),
      this.institution._id,
    ).subscribe((institution) => {
      this.toastService.success('Institution bearbeitet', 'Ihre Institution wurde erfolgreich bearbeitet.');
      this.institution = institution;
      this.institutionFormComponent.updateValues(this.institution);
    }, error => {
      this.toastService.error('Institution nicht bearbeitet', 'Ihre Institution konnte nicht bearbeitet werden.', error);
    });
  }
}
