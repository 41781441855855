import {Component, OnInit} from '@angular/core';
import {clients} from '../../core/constants/clients.config';
import {clientRoles} from '../../core/constants/client-roles.config';
import {KeycloakService} from 'keycloak-angular';

@Component({
  selector: 'app-subadmin',
  templateUrl: './subadmin.component.html',
  styleUrls: ['./subadmin.component.css'],
  standalone: false,
})
export class SubadminComponent implements OnInit {
  public clientRoles = clientRoles;
  public userRoles: string[] = [];

  constructor(
    private keycloakService: KeycloakService,
  ) {
  }

  ngOnInit() {
    this.userRoles = this.keycloakService.getUserRoles(false, clients.API);
  }
}
