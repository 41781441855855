import {NgModule} from '@angular/core';

import {SubadminComponent} from './subadmin.component';
import {SharedFormsModule} from '../../shared/forms/shared-forms.module';
import {SubadminRoutingModule} from './subadmin-routing.module';
import {ManageOwnInstitutionComponent} from './pages/manage-own-institution/manage-own-institution.component';
import {ManageInternalMessagesComponent} from './pages/manage-internal-messages/manage-internal-messages.component';
import {SharedComponentsModule} from '../../shared/components/shared-components.module';
import {SuiPaginationModule} from '@angular-ex/semantic-ui';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    ManageOwnInstitutionComponent,
    SubadminComponent,
    ManageInternalMessagesComponent,
  ],
  imports: [
    SubadminRoutingModule,
    SharedFormsModule,
    SharedComponentsModule,
    SuiPaginationModule,
    CommonModule,
  ]
})
export class SubadminModule {
}
